import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Products from '../../../components/Products';
import ContentImage from '../../../components/ContentImage';
import image_1 from '../../../images/products/vibration-monitoring-system/MVMS-KDV1標準版振動感測器.png';
import image_2 from '../../../images/products/vibration-monitoring-system/MVME-KDV1進階版振動感測器.png';

const ProductsPage = () => {
  const { t } = useTranslation('products_vibration-monitoring-system');
  return (
    <Products pageTitle={t('title')} description={t('description')}>
      <ContentImage image={image_1} title={t('t1.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t1.blurb')}
        </p>
      </ContentImage>
      <ContentImage reverse={true} image={image_2} title={t('t2.name')}>
        <p className="mb-6 font-normal text-base text-gray-600">
          {t('t2.blurb')}
        </p>
      </ContentImage>
    </Products>
  );
};

export default ProductsPage;
